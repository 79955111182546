import { track } from '@/utils/analytics'
import {
  normalizeAltExperienceImg,
  normalizeItem,
  normalizeUrl,
} from '@/utils/normalizeUrl'
import { Image } from '@nextui-org/react'
import fullHeartIcon from 'assets/svgs/fullHeartIcon.svg'
import emptyHeartIcon from 'assets/svgs/emptyHeartIcon.svg'
import { CleverEvents } from '@/utils/events'
import { getCountryLocaleString } from '@/utils/generalTitle.Util'
import { ExperienceType } from '@/constants/generalCarousel'
import { amountFormatter } from '@/utils/amountFormatter'
import { getPriceRegularOrstaggered } from '@/utils/common'

const ExperienceCard = ({
  experience,
  screenName,
  addFavorite,
  t,
  defaultLang,
  navigate,
}) => {
  return (
    <div
      className="h-[310px] lg:h-[435px] overflow-hidden shadow-md rounded-b-2xl  grid grid-rows-[auto,1fr] cursor-pointer"
      role="link"
      onClick={() => {
        window.open(normalizeUrl(experience, defaultLang), '_blank')
        track('Experience View', {
          experienceName: experience?.name,
          experienceId: experience?.id,
          screen: screenName,
        })
      }}
    >
      <div className="relative max-w-full h-40 lg:h-64 overflow-hidden rounded-t-2xl">
        <Image
          removeWrapper
          isZoomed
          className="object-cover w-full h-full rounded-none"
          alt={normalizeAltExperienceImg(experience)}
          src={experience?.photos_url?.[0]}
        />
        <img
          src={!!!experience.favorite ? emptyHeartIcon : fullHeartIcon}
          role="none"
          className="absolute right-6 top-6 w-7 h-6 !bg-transparent z-10 hover:scale-110 active:scale-125"
          onClick={(e) => addFavorite(e, experience)}
          alt="heart icon svg"
        />
      </div>
      <div className="p-3 flex flex-col justify-between">
        <h3 className="text-base lg:text-xl text-black leading-5 md:!leading-6 line-clamp-3">
          {experience?.name}
        </h3>
        <div>
          {experience?.country ? (
            <div
              className="text-xs lg:text-base text-gotuuriCyan w-fit"
              onClick={(e) => {
                window.scroll(0, 0)
                e.stopPropagation()
                CleverEvents.CountryView({
                  name: experience?.country?.name,
                  id: experience?.country?.id,
                  screen: 'Experience',
                })
                navigate(
                  normalizeItem({
                    ...experience?.country,
                    type: ExperienceType.COUNTRY,
                  })
                )
              }}
              role="link"
            >
              {t(getCountryLocaleString(experience?.country?.iso_code))}
            </div>
          ) : (
            ''
          )}
          <div className="flex justify-between items-end -mt-1">
            <div className="flex flex-col w-full">
              <p className=" text-xs text-darkGray mt-px">
                {experience?.price_adults
                  ? t('experienceList.priceStart')
                  : t('experienceList.pricePerGroup')}
              </p>
              <div className="flex justify-between content-center items-center">
                <h3 className="text-xl md:text-3xl leading-6">
                  {getPriceRegularOrstaggered(experience)}
                </h3>

                {experience?.rating ? (
                  <div className="rounded-3xl h-6 bg-gray-200 flex justify-center items-center px-2">
                    <p className="mr-px">★</p>
                    <p className="text-center text-xs">
                      {experience.rating.toFixed(1)}
                    </p>
                  </div>
                ) : null}
              </div>

              {experience?.original_price > 0 &&
                experience?.show_original_price && (
                  <h3 className="text-xs md:text-lg text-lightGray line-through -mt-1">
                    {amountFormatter(experience?.original_price)}
                  </h3>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExperienceCard
