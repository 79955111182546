import React, { useEffect, useState } from 'react'
import { Button } from '@nextui-org/react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { CalendarRow } from './CalendarRow'
import SelectHourRow from './SelectHourRow/SelectHourRow'
import { ParticipantsRow } from './ParticipantsRow'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useTranslation } from 'react-i18next'
import { useIsMobile } from '@/utils/mobile'
import { MobileBookModal } from './MobileBookModal'
import { BookingMobileFooter } from '../BookingMobileFooter'
import { useNavigate } from 'react-router-dom'
import getLocalBookingData from '@/utils/getLocalBookingData'
import UniqueDatesRow from './UniqueDatesRow/UniqueDatesRow'
import { amountFormatter } from '@/utils/amountFormatter'
import { AdditionalProductsRow } from './AdditionalProductsRow'
import { googleTrack, track } from '@/utils/analytics'
import { USD } from '@/constants/common'
import { calculatePrice } from '@/utils/calculatePrice'
import DisclosureIcon from '@/assets/svgs/difficulty.svg'

interface totalParticipantsProps {
  adults: number
  children: number
  babies: number
  seniors: number
}

const BookingCard = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  getLocalBookingData()

  const isMobile = useIsMobile()
  const {
    state: {
      experience,
      isLoggedIn,
      bookingData,
      uniqueDates,
      locale,
      availableHours,
      user,
    },
    hooks: { setBookingData },
  } = useGotuuriContext()
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const [totalPrice, setTotalPrice] = useState(null)
  const [totalParticipantQuantity, setTotalQuantity] = useState(1)
  const [selectedHourIndex, setSelectedHourIndex] = useState(null)
  const [bookingHour, setBookingHour] = useState(null)
  const [bookingDate, setBookingDate] = useState(null)
  const [totalParticipants, setTotalParticipants] = useState(null)
  const [additionalAmount, setAdditionalAmount] = useState(null)
  const [totalProducts, setTotalProducts] = useState([])
  const [rangeDiscount, setRangeDiscount] = useState(null)

  const handleSelectHour = (schedule, index) => {
    googleTrack('ExperienceTimeSelect')
    setBookingHour(schedule)
    setSelectedHourIndex(index)
  }
  const handleTotalParticipants = (
    totalParticipants: totalParticipantsProps
  ) => {
    if (!!experience && !!totalParticipants)
      setTotalQuantity(
        Object.values(totalParticipants).reduce((acc, value) => acc + value, 0)
      )
    googleTrack('ExperienceParticipantSelect')
    setTotalParticipants(totalParticipants)
  }

  const handleBookButton = () => {
    const hasAdditionalTransport = !!totalProducts?.some(
      (product) => product.additional_item?.type.code === 0
    )
    const hasTransport = hasAdditionalTransport || !experience?.meeting_latitude
    setBookingData({
      ...bookingData,
      createdAt: new Date(),
      experience: experience,
      bookingHour: bookingHour,
      totalParticipantQuantity: totalParticipantQuantity,
      bookingDate: bookingDate,
      totalParticipants: totalParticipants,
      totalPrice: totalPrice + additionalAmount,
      additionalAmount: additionalAmount,
      totalProducts: totalProducts,
      coupon: null,
      rangeDiscount: rangeDiscount,
      hasTransport: hasTransport,
    })

    !!totalProducts?.length &&
      track('Experience Add-ons Select', {
        addOnType: totalProducts
          ?.map((product) => product.additional_item?.name)
          .join(', '),
      })
    track('Experience Participant Select', {
      participantAmount: totalParticipantQuantity,
      participantAdults: totalParticipants?.adults,
      participantChildren: totalParticipants?.children,
      participantBabies: totalParticipants?.babies,
      participantElders: totalParticipants?.seniors,
    })
    track('Insurance Details View')

    googleTrack('BookNow', {
      ecommerce: {
        value: totalPrice,
        currency: USD,
        mobile: isMobile,
        referent: user?.referent,
        experiences: [
          {
            experienceId: experience.id,
            experienceName: experience.name,
            destinationID: experience.city.id,
            destinationName: experience.city.name,
            tuuriId: experience.companies[0].id,
            tuuriName: experience.companies[0].company_name,
            language: locale,
            loggedIn: isLoggedIn,
            price: experience.price_adults,
            quantity: totalParticipantQuantity,
            adults: totalParticipants.adults,
            children: totalParticipants.children,
            babies: totalParticipants.babies,
          },
        ],
      },
    })
    navigate('/booking/payment')
    setIsMobileOpen(false)
  }

  useEffect(() => {
    if (!!experience) {
      setTotalPrice(experience.price_package ?? experience.price_adults)
    }
  }, [experience, uniqueDates])

  useEffect(() => {
    if (!!experience && !!totalParticipants) {
      setTotalPrice(
        calculatePrice(
          experience,
          totalParticipants,
          totalParticipantQuantity,
          setAdditionalAmount,
          totalProducts,
          setRangeDiscount,
          !!experience?.price_package
        ) ?? 0
      )
    }
  }, [
    totalParticipants,
    totalProducts,
    availableHours,
    totalParticipantQuantity,
  ])

  return (
    <>
      {!isMobile ? (
        <section className="flex-1 sticky top-12 h-max hidden md:block w-1/4">
          <div className="h-full shadow-md rounded-2xl px-6 py-4">
            <div className="flex flex-col lg:flex-row mb-4 lg:mb-6">
              <div className="flex flex-col">
                {experience?.original_price > 0 &&
                  experience?.show_original_price && (
                    <h3 className="text-xl md:text-1xl leading-6 text-lightGray line-through">
                      {amountFormatter(experience.original_price)}
                    </h3>
                  )}
                <h3 className="text-4xl xl:text-[42px] font-semibold relative">
                  {amountFormatter(totalPrice + additionalAmount)}{' '}
                  {/* <span className="text-xs text-gray-600 absolute">
                    {!!rangeDiscount && `(-${rangeDiscount}%)`}
                  </span> */}
                </h3>
              </div>

              <p className="text-sm text-gray-400 mt-auto ml-4 truncate">
                {experience?.price_package
                  ? t('bookingCard.perGroup')
                  : t('bookingCard.perPerson', {
                      count: totalParticipantQuantity,
                    })}
              </p>
            </div>
            {!!!uniqueDates ? (
              <CalendarRow handleBookingDate={setBookingDate} />
            ) : uniqueDates?.dates.length < 1 ? (
              <CalendarRow
                handleBookingDate={setBookingDate}
                isDateRange={true}
                rangeMinDate={uniqueDates?.start_date}
                rangeMaxDate={uniqueDates?.end_date}
              />
            ) : (
              <UniqueDatesRow
                uniqueDates={uniqueDates}
                handleBookingDate={setBookingDate}
              />
            )}
            <SelectHourRow
              bookingDate={bookingDate}
              handleSelectHour={handleSelectHour}
              bookingHour={bookingHour}
              setBookingHour={setBookingHour}
            />
            <ParticipantsRow
              selectedHourIndex={selectedHourIndex}
              handleTotalParticipants={handleTotalParticipants}
            />
            {!!experience?.additional_products.length ? (
              <AdditionalProductsRow
                additionalProducts={experience?.additional_products ?? []}
                setAdditionalAmount={setAdditionalAmount}
                totalProducts={totalProducts}
                setTotalProducts={setTotalProducts}
                bookingData={bookingData}
              />
            ) : null}
            <p className=" text-gray-400 w-full text-center leading-4 my-4 lg:my-8 text-sm">
              {experience?.is_package ? (
                t('bookingCard.perGroupExperience', {
                  company: experience.companies[0].company_name,
                  maxPersons: experience.max_persons,
                  pricePackage: experience.price_package,
                })
              ) : (
                <>
                  Min: {experience?.min_persons} - Max:{' '}
                  {availableHours?.[selectedHourIndex]?.available_places ??
                    experience?.max_persons}
                </>
              )}
            </p>
            {experience?.price_discount_groups?.length > 0 && (
              <div className="flex flex-row gap-2 content-center mb-3">
                <div className="mt-1">
                  <img src={DisclosureIcon} width={18} alt="disclosure icon" />
                </div>
                <p className="text-[12px] text-[#616161]">
                  {t('bookingCard.priceDiscountGroupsInfo')}
                </p>
              </div>
            )}

            <Button
              onClick={() => {
                handleBookButton()
              }}
              isDisabled={
                !!!bookingHour ||
                !!!bookingDate ||
                totalParticipantQuantity < experience?.min_persons
              }
              className="!bg-black disabled:!bg-[#e1e1e1] disabled:!text-black text-white rounded-3xl md:text-sm lg:text-base w-full h-12"
            >
              {t('bookingCard.book')}
            </Button>
            {/* <Button
              radius="full"
              variant="bordered"
              onClick={() => {
                navigate(
                  `/giftcards?experience=${experience?.id}&price=${(totalPrice + additionalAmount).toFixed(2)}`
                )
              }}
              className="rounded-3xl md:text-sm lg:text-base w-full h-12 mt-4 text-darkGray border-darkGray border-1 group hover:bg-gotuuriCyan hover:text-white hover:border-gotuuriCyan"
            >
              <GifttIcon className="w-5 h-5 text-darkGray group-hover:text-white transition-all" />
              Regala esta actividad
            </Button> */}
          </div>
        </section>
      ) : (
        <>
          <BookingMobileFooter
            priceLabel={
              experience?.price_adults
                ? t('bookingCard.priceStart')
                : t('bookingCard.pricePerGroup')
            }
            price={amountFormatter(totalPrice)}
            onPressAction={() => setIsMobileOpen(true)}
            buttonLabel={t('bookingCard.schedules')}
            reverse={true}
          />
          <MobileBookModal
            totalProducts={totalProducts}
            setTotalProducts={setTotalProducts}
            additionalAmount={additionalAmount}
            setAdditionalAmount={setAdditionalAmount}
            bookingDate={bookingDate}
            handleBookingDate={setBookingDate}
            handleBookButton={handleBookButton}
            isMobileOpen={isMobileOpen}
            setIsMobileOpen={setIsMobileOpen}
            setTotalPrice={setTotalPrice}
            handleSelectHour={handleSelectHour}
            selectedHourIndex={selectedHourIndex}
            handleTotalParticipants={handleTotalParticipants}
            totalPrice={totalPrice}
            totalParticipantQuantity={totalParticipantQuantity}
            bookingHour={bookingHour}
            setBookingHour={setBookingHour}
            rangeDiscount={rangeDiscount}
          />
        </>
      )}
    </>
  )
}
export default BookingCard
