import { amountFormatter } from "./amountFormatter"

export const scrollToElement = (elementId: string) => {
  const element = document.getElementById(elementId)
  if (element) {
    const yOffset = -100
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}

export const getPriceRegularOrstaggered = (experience) => {
  const { price_discount_groups, price_adults, price_package } =
    experience || {}

  if (price_discount_groups?.length > 0) {
    const lastDiscountGroup =
      price_discount_groups[price_discount_groups.length - 1]
    const discount = (price_adults * lastDiscountGroup.discount) / 100
    return amountFormatter(price_adults - discount)
  }

  return amountFormatter(price_adults ?? price_package)
}
